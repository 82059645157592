import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

interface Props {
	data: {
		site: {
			siteMetadata: {
				title: string
			}
		}
	}
}

const NotFoundPage = ({ data }: Props) => {
	return (
		<Layout>
			<SEO title="404: Not Found" />
			<h1>Not Found</h1>
			<p>You just hit a route that doesn't exist... the sadness.</p>
		</Layout>
	)
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`